import { downloadFile } from '../buyer/services/buyer-fetch-with-token';
import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

class DownloadSheetService {
  static async downloadCustomerProfilesSheet(buyerSiteIds, sellerSiteName) {
    const url = `${AppSettings.GRAPHQL_SERVER_URL}export/customer-profiles`;
    return fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify({ buyerSiteIds }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        throw new Error(response.statusText);
      })
      .then((data) =>
        downloadFile(data, `Spoiler Alert-${sellerSiteName}-Customer Profiles Sheet-${new Date().toISOString().substring(0, 10)}`, mimeType)
      )
      .then(() => ({ success: true }))
      .catch((error) => ({ success: false, errorMessage: error }));
  }
}

export default DownloadSheetService;
