export default {
  legacyDashboard: 'LEGACY DASHBOARD',
  acceptAllOffers: 'ACCEPT ALL OFFERS',
  donationsv2: 'DONATIONS V2',
  archiveInventory: 'ARCHIVE INVENTORY',
  customerProfiles: 'CUSTOMER PROFILES',
  distributionListSettings: 'DISTRIBUTION LIST SETTINGS',
  companyLogo: 'COMPANY LOGO',
  offerDueDate: 'OFFER DUE DATE',
  trucklaneView: 'TRUCKLANE VIEW',
  showNonProdSidebar: 'SHOW NON PROD SIDEBAR',
  historicalOffers: 'HISTORICAL OFFERS',
  marketplaceBidStrength: 'MARKETPLACE BID STRENGTH',
  virtualizedDataTable: 'VIRTUALIZED DATA TABLE',
  offerShift: 'OFFER SHIFT',
  buyerEmailReminder: 'BUYER EMAIL REMINDER',
  negotiationContext: 'NEGOTIATION CONTEXT',
  marketplace: 'MARKETPLACE STOREFRONT',
  marketplaceOrdersPage: 'MARKETPLACE AWARD EMAILS',
  sendLossEmails: 'SEND LOSS EMAILS',
  suggestedNegotiations: 'SUGGESTED NEGOTIATIONS',
  certifiedBuyers: 'CERTIFIED BUYERS DL',
  postAwardUpdates: 'POST AWARD UPDATES',
};
