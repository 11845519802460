const routePaths = {
  home: '/',
  dashboard: '/dashboard',
  enterpriseDashboard: '/enterprise/dashboard',
  platformDashboard: '/dashboard/platform',
  reportsDashboard: '/reports',
  signIn: '/sign-in',
  ssoSignIn: '/sso',
  ssoTokenExchange: '/exchange',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/',
  unverified: '/unverified',
  registerInvitee: '/register-invitee/',
  logout: '/logout',
  changePassword: '/change-password',
  taxDashboard: '/tax',
  taxAbout: '/tax/about',
  taxReceipt: '/tax/receipt/',
  logTransaction: '/log/transaction',
  editTransaction: '/edit/transaction/',
  post: '/post/',
  marketplace: '/marketplace',
  myPosts: '/activity/my-posts',
  myClaims: '/activity/my-claims',
  myVerifications: '/activity/my-verifications',
  pendingVerifications: '/pending-verifications',
  userManagement: '/users',
  proxy: '/proxy/',
  items: '/items',
  itemCatalog: '/items/catalog',
  itemAbout: '/items/about',
  itemHistory: '/items/history',
  itemHistoryDetail: '/item/history/',
  itemImport: '/items/import/file',
  orgSettings: '/organization/settings',
  siteSettings: '/site/settings',
  inventory: '/inventory',
  staging: '/staging',
  review: '/review',
  offerReview: '/review/offers',
  donationImport: '/import-donations',
  addSite: '/administration/addsite',
  impersonate: '/administration/impersonate',
  supportSSO: '/support/sso',
  offerComparison: '/offer-comparison',
  negotiations: '/negotiations',
  suggestedNegotiations: '/suggested-negotiations',
  history: '/history',
  notFound: '/404',
  error: '/error',
  transactions: '/transactions',
  customers: '/customers',
  buyerRoutes: {
    prefix: '/buyer',
    landing: '',
    expiredCycle: '/expired',
    brokenLink: '/broken-link',
    suffix: '/:buyerAuthToken',
    auctions: '/auctions',
    notifications: '/notifications',
    marketplace: '/marketplace',
    orderHistory: '/order-history',
  },
  companyLogo: '/company-logo',
};

export default routePaths;
