function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}

export default function isTokenExpired(token) {
  const decoded = parseJwt(token);
  const expirationEpochSeconds = decoded.exp;
  const nowEpochSeconds = Math.floor(new Date().getTime() / 1000);
  return nowEpochSeconds > expirationEpochSeconds;
}
